// @ts-nocheck
import { useQuery } from '@apollo/client';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Tooltip,
  Typography,
  Alert
} from '@mui/material';
import { EventSubForm } from 'components/Form/EventForm';
import { Field, Form, Formik } from 'formik';
import { groupUsersQuery } from 'graphql/querys/groupUsers';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { formatCognitoUsers } from 'utils/formatCognitoUsers';
import { formatTreatmentPlans } from 'utils/formatTreatmentPlans';
import * as yup from 'yup';
import Select from '../../Form/SelectWrapper';
import SubmitButtonWrapper from '../../Form/SubmitButtonWrapper';
import TextField from '../../Form/TextFieldWrapper';
import { ConsultationFiles } from '../../files/ConsultationFiles';
import UploadFiles from '../../files/UploadFiles';
import TreatmentTable from '../treatmentPlans/TreatmentTable';
import { paymentByConsultationId } from '../../../graphql/querys/paymentByConsultationIdQuery';
import { PaymentsByConsultation } from '../userdetail/PaymentsByConsultation';

const FORM_VALIDATION = yup
  .object()
  .shape({
    consultationDescription: yup.string().required('La descripcion de la consulta es necesaria'),
    event: yup.object(),
    doctor: yup.string(),
    notes: yup.string(),
    totalAmount: yup
      .number()
      .integer()
      .typeError('Por favor ingrese un monto valido')
      .required('El monto de la consulta es necesario'),
    payedAmount: yup
      .number()
      .typeError('Por favor ingrese un monto valido')
      .max(
        yup.ref('totalAmount'),
        'El monto cancelado debe ser inferior al monto total de la consulta'
      )
      .min(0, 'El monto cancelado debe ser superior o igual a 0'),
    treatmentPlanId: yup.string()
  })
  .required();

const date = new Date().toISOString().split('T');
console.log(date);

const INITAL_FORM_STATE = {
  consultationDescription: '',
  doctor: 'indefinido',
  notes: '',
  payedAmount: 0,
  totalAmount: '',
  event: {},
  treatmentPlanId: 'indefinido'
};

export function ConsultationForm(props) {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [currentValues, setCurrentValues] = useState(props.values);
  const [selectedTreatmentIds, setSelectedTreatmentsIds] = useState([]);

  const consultationId = params?.consultationId;

  const {
    data: paymentData,
    loading: paymentLoading,
    error: paymentError
  } = useQuery(paymentByConsultationId, {
    variables: { paymentByConsultationIdId: consultationId },
    fetchPolicy: 'network-only',
    skip: !consultationId // This will skip the query if consultationId is null
  });

  const hasPayments =
    paymentData &&
    paymentData.paymentByConsultationId &&
    paymentData.paymentByConsultationId.length > 0;

  useEffect(() => {
    setCurrentValues(props.values);
    if (currentValues?.treatments) {
      setSelectedTreatmentsIds(currentValues.treatments.map((t) => t.id));
    }
  }, [props.values]);

  if (props.values) {
    currentValues.doctor = currentValues.event.mandatoryPresence?.split(',')[0] || 'indefinido';
    currentValues.treatmentPlanId = currentValues.treatmentPlanId || 'indefinido';
  }
  const [files, setFiles] = useState([]);
  const [isConsultationInfoLocked, setIsConsultationInfoLocked] = useState(!!props.values);
  const handleConsultationInfoChange = (event) => {
    setIsConsultationInfoLocked(!event.target.checked);
  };
  const { data: groupUsers, loading: groupUsersLoading } = useQuery(groupUsersQuery);
  let formattedUsers = {
    indefinido: '(indefinido)'
  };
  let formattedTreatmentPlans = {
    indefinido: '(no plan de tratamiento)'
  };
  const { data: treatmentPlansResponse, loading: treatmentPlansLoading } = useQuery(
    treatmentPlansByPatientId,
    {
      variables: { id: props.patientId }
    }
  );
  if (!treatmentPlansLoading) {
    formattedTreatmentPlans = formatTreatmentPlans(
      treatmentPlansResponse.treatmentPlansByPatientId,
      formattedTreatmentPlans
    );
  }
  // @ts-ignore
  if (groupUsers) formattedUsers = formatCognitoUsers(groupUsers.getGroupUsers, formattedUsers);

  return (
    <Formik
      initialValues={
        props.values
          ? currentValues
          : {
              ...INITAL_FORM_STATE,
              treatmentPlanId: searchParams.get('treatmentPlan') || 'indefinido'
            }
      }
      enableReinitialize
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values) => {
        props.onSubmit(
          {
            ..._values,
            treatmentPlanId:
              _values.treatmentPlanId === 'indefinido' ? null : _values.treatmentPlanId,
            treatmentsIds: _values.treatmentPlanId === 'indefinido' ? null : selectedTreatmentIds
          },
          files
        );
        setFiles([]);
      }}
    >
      {(form) => {
        const isTreatmentPlanSelected = form.values?.treatmentPlanId !== 'indefinido';
        const selectedTreatmentPlan =
          isTreatmentPlanSelected && !treatmentPlansLoading
            ? treatmentPlansResponse.treatmentPlansByPatientId.find(
                (tp) => tp.id === form.values.treatmentPlanId
              )
            : null;
        // eslint-disable-next-line react/prop-types

        props.setSelectedTreatmentPlanId?.(
          selectedTreatmentPlan ? selectedTreatmentPlan.id : 'indefinido'
        );

        const selectedTreatments =
          isTreatmentPlanSelected && selectedTreatmentPlan != null
            ? selectedTreatmentPlan.treatments.filter((treatments) =>
                selectedTreatmentIds.includes(treatments.id)
              )
            : [];

        let totalPriceSelectedTreatments = 0;
        selectedTreatments.forEach(
          (treatment) => (totalPriceSelectedTreatments += treatment.price)
        );
        if (isTreatmentPlanSelected && form.values.totalAmount !== totalPriceSelectedTreatments) {
          form.setFieldValue('totalAmount', totalPriceSelectedTreatments);
        }
        return (
          <Form>
            <Grid
              container
              rowSpacing={5}
              columnSpacing={3}
              columns={{ xs: 12 }}
              mb={3}
              p={{ xs: 1, sm: 3 }}
            >
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom pt={3}>
                  Información de la consulta
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right', marginTop: '21px' }}>
                {props.values && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!isConsultationInfoLocked}
                        onChange={handleConsultationInfoChange}
                      />
                    }
                    label="Editar datos"
                    labelPlacement="start"
                  />
                )}
              </Grid>
            </Grid>
            <Tooltip
              title={isConsultationInfoLocked ? 'Haga clic en "Editar datos" para editar' : ''}
              followCursor
            >
              <Grid
                container
                rowSpacing={5}
                columnSpacing={3}
                columns={{ xs: 6, sm: 6, md: 12 }}
                p={{ xs: 2, sm: 3 }}
              >
                <Grid item xs={6}>
                  <TextField
                    name="consultationDescription"
                    label="Motivo de la consulta"
                    inputProps={{
                      readOnly: isConsultationInfoLocked
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="event"
                    component={EventSubForm({
                      isStateDisabled: props.values ? props.values.payedAmount !== 0 : false,
                      isDisabled: isConsultationInfoLocked
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="notes"
                    label="Notas"
                    multiline
                    rows={4}
                    variant="outlined"
                    inputProps={{
                      readOnly: isConsultationInfoLocked
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {groupUsersLoading && <p>Loading doctors...</p>}
                  {!groupUsersLoading && (
                    <Select
                      name="doctor"
                      label="Doctor"
                      options={formattedUsers}
                      inputProps={{
                        readOnly: isConsultationInfoLocked
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      isTreatmentPlanSelected
                        ? 'Dado que esta consulta está asociada a un plan de tratamiento, para cambiar su monto debe seleccionar un tratamiento de la tabla o eliminar el plan de tratamiento de la consulta.'
                        : ''
                    }
                    followCursor
                  >
                    <div style={{ opacity: isTreatmentPlanSelected ? 0.6 : 1 }}>
                      <TextField
                        name="totalAmount"
                        label="Monto de la consulta"
                        variant="outlined"
                        InputProps={{
                          readOnly: isConsultationInfoLocked || isTreatmentPlanSelected,
                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ opacity: hasPayments ? 0.6 : 1 }}>
                    {treatmentPlansLoading && <p>Loading planes de tratamiento...</p>}
                    {!treatmentPlansLoading && (
                      <Select
                        name="treatmentPlanId"
                        label="Plan de tratamiento"
                        options={formattedTreatmentPlans}
                        inputProps={{
                          readOnly: isConsultationInfoLocked || hasPayments
                        }}
                      />
                    )}
                  </div>
                </Grid>
                {hasPayments && !isConsultationInfoLocked && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Solo se pueden agregar o eliminar tratamientos, o cambiar un plan de
                      tratamiento cuando la consulta no tiene pagos. Elimine los pagos para realizar
                      estos cambios.
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div style={{ opacity: hasPayments && !isConsultationInfoLocked ? 0.6 : 1 }}>
                    <TreatmentTable
                      treatments={
                        isTreatmentPlanSelected && selectedTreatmentPlan !== null
                          ? selectedTreatmentPlan.treatments
                          : []
                      }
                      selectedTreatmentsIds={selectedTreatmentIds}
                      selectable
                      disabled={hasPayments}
                      onSelectChange={setSelectedTreatmentsIds}
                      editingConsultationId={consultationId}
                      readOnly={isConsultationInfoLocked || hasPayments}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {props.values && (
                    <TextField
                      name="payedAmount"
                      label="Monto cancelado"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  )}
                </Grid>
                {props.values ? (
                  <Grid item xs={12} style={{ width: '100%' }}>
                    <PaymentsByConsultation
                      paymentData={paymentData}
                      paymentLoading={paymentLoading}
                    />
                  </Grid>
                ) : null}
                {props.values ? (
                  <Grid item xs={12}>
                    <ConsultationFiles />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  {!isConsultationInfoLocked && <UploadFiles files={files} setFiles={setFiles} />}
                </Grid>
                <Grid item xs={12}>
                  <SubmitButtonWrapper>Guardar Consulta</SubmitButtonWrapper>
                </Grid>
              </Grid>
            </Tooltip>
          </Form>
        );
      }}
    </Formik>
  );
}
