// @ts-nocheck
import { useMutation, useQuery } from '@apollo/client';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import Page from 'components/Page';
import { TreatmentPlanProgress } from 'components/_dashboard/consultations/TreatmentPlanProgress';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TreatmentPlanConsultations } from 'components/_dashboard/treatmentPlans/TreatmentPlanConsultations';
import TreatmentPlanPDF from 'components/_dashboard/treatmentPlans/TreatmentPlanPdf';
import TreatmentTable from 'components/_dashboard/treatmentPlans/TreatmentTable';
import { consultationsByTreatmentPlanId } from 'graphql/querys/consultationsByTreatmentPlanIdQuery';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { PatientDetailById } from 'graphql/querys/patientDetailByIdQuery';
import { Link, useParams } from 'react-router-dom';

export default function TreatmentPlanDetail() {
  const { id, treatmentPlanId } = useParams();

  const { data: patientData } = useQuery(PatientDetailById, {
    variables: { id }
  });

  const { data: treatmentPlanConsultations } = useQuery(consultationsByTreatmentPlanId, {
    variables: { consultationsByTreatmentPlanIdId: treatmentPlanId }
  });

  const doneConsultations = treatmentPlanConsultations?.consultationsByTreatmentPlanId?.filter(
    (consultation) => consultation.event.state === 'done'
  );

  const doneTreatments = doneConsultations?.map((consultation) => consultation.treatments).flat();

  const { data: pData, loading: dataLoading } = useQuery(treatmentPlansByPatientId, {
    variables: { id }
  });
  if (dataLoading) {
    return <Skeleton />;
  }
  const treatmentPlan = pData.treatmentPlansByPatientId.filter(
    (tp) => tp.id === treatmentPlanId
  )[0];

  const sumOfPrices = treatmentPlan.treatments
    .map((t) => t.price)
    .reduce((curr, next) => curr + next, 0);

  return (
    <Page title="Dental by Aronin">
      <Box pb={4}>
        <Container>
          <Card sx={{ boxShadow: 3 }}>
            <CardHeader
              title="Información del plan de tratamiento"
              titleTypographyProps={{ variant: 'h3' }}
              action={
                <PDFDownloadLink
                  document={
                    <TreatmentPlanPDF
                      treatmentPlan={treatmentPlan}
                      sumOfPrices={sumOfPrices}
                      patientData={patientData}
                    />
                  }
                  fileName={`treatment_plan_${treatmentPlan.id}.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <Button variant="contained" disabled={loading}>
                      {loading ? 'Cargando documento...' : 'Descargar'}
                    </Button>
                  )}
                </PDFDownloadLink>
              }
            />
            <CardContent>
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Grid container spacing={2} columns={{ xs: 6, sm: 12 }}>
                  <Grid xs={6} sm={6}>
                    Nombre del plan de tratamiento
                    <Typography variant="h6">{treatmentPlan.name}</Typography>
                  </Grid>
                  <Grid xs={4} sm={4}>
                    Monto del plan de tratamiento
                    <Typography variant="h6">{sumOfPrices}</Typography>
                  </Grid>
                  <Grid xs={2} sm={2}>
                    Progreso
                    <br />
                    <TreatmentPlanProgress
                      value={doneTreatments?.length || 0}
                      over={treatmentPlan.treatments.length}
                      size={60}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <br />
      <Container>
        <Card sx={{ boxShadow: 3, margin: { xs: -2, sm: 0 } }}>
          <CardContent sx={{ margin: { xs: -4, sm: 0 } }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Tratamientos
                </Typography>
                {/* TODO: Add button to change treatment plan */}
                {/* <Button variant="contained">Ingresar / Cambiar</Button>  */}
              </Stack>
              <TreatmentTable treatments={treatmentPlan.treatments} selectable={false} />
              <br />
              <br />
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Consultas del plan de tratamiento
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={`/dashboard/createConsultation/${id}?treatmentPlan=${treatmentPlan.id}`}
                  startIcon={<Icon icon={plusFill} />}
                >
                  Nueva consulta
                </Button>
              </Stack>
              <TreatmentPlanConsultations treatmentPlanId={treatmentPlanId} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
