// @ts-nocheck
import {
  Alert,
  FormControlLabel,
  Grid,
  Snackbar,
  Button,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import HandleFocusAndBlur from 'components/Form/HandleFocusAndBlur';
import ToolTipWrapper from 'components/Form/ToolTipWrapper';
import { Form, Formik } from 'formik';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useRef, useState, useEffect } from 'react';
import * as yup from 'yup';
import { PatientFiles } from '../../files/PatientFiles';
import UploadFiles from '../../files/UploadFiles';
import DateTimePicker from '../../Form/DateTimeWrapper';
import Select from '../../Form/SelectWrapper';
import SubmitButtonWrapper from '../../Form/SubmitButtonWrapper';
import TextField from '../../Form/TextFieldWrapper';
import { InsertSignature } from '../userdetail/InsertSignature';
import { Signatures } from '../signatures/Signatures';
import UserMedicalHistoryPDF from './userMedicalHistoryPdf';

const FORM_VALIDATION = yup
  .object()
  .shape({
    firstName: yup.string().required('El nombre del paciente es necesario'),
    lastName: yup.string().required('El apellido del paciente es necesario'),
    sex: yup.string(),
    dateOfBirth: yup.date().nullable(),
    address: yup.string(),
    cedula: yup.number().integer().typeError('Por favor ingrese un número de cedula valido'),
    placeOfBirth: yup.string(),
    email: yup.string().email(),
    phoneNumber: yup
      .number()
      .integer()
      .typeError('Por favor ingrese un número de teléfono valido')
      .required('El número de teléfono del paciente es necesario'),
    emergencyPhoneNumber: yup
      .number()
      .integer()
      .typeError('Por favor ingrese un número de teléfono valido'),
    ocupation: yup.string(),
    medicalAntecedents: yup.string(),
    oralHistory: yup.string(),
    habits: yup.string(),
    allergies: yup.string(),
    familyAntecedents: yup.string().nullable()
  })
  .required();

const INITAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  sex: '',
  address: '',
  cedula: '',
  placeOfBirth: '',
  email: '',
  phoneNumber: '',
  emergencyPhoneNumber: '',
  ocupation: '',
  medicalAntecedents: '',
  oralHistory: '',
  habits: '',
  allergies: '',
  familyAntecedents: ''
};

function getAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  // Calculate the difference in years
  let age = today.getFullYear() - birthDateObj.getFullYear();
  // Adjust for months and days (if birthday hasn't passed yet this year)
  const month = today.getMonth() - birthDateObj.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDateObj.getDate())) {
    age -= 1;
  }

  return age;
}

export function UserForm(props) {
  const values = { ...props.values };
  const signRef = useRef();
  console.log(values);
  if (props.values && props.values.dateOfBirth) {
    // Date of birth can be null, so we format it only if it's defined
    const date = new Date(Number(props.values.dateOfBirth));
    [values.dateOfBirth] = date.toISOString().split('T');
  }
  // State variables for PDF generation loading
  const [showPdfLoading, setShowPdfLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    let pdfTimer;
    if (isPdfLoading) {
      // Debounce time for PDF generation loading indicator
      pdfTimer = setTimeout(() => {
        setShowPdfLoading(true);
      }, 10); // Set the debounce time to 10ms for PDF generation
    } else {
      setShowPdfLoading(false);
    }

    return () => clearTimeout(pdfTimer);
  }, [isPdfLoading]);

  const [isPersonalInfoLocked, setIsPersonalInfoLocked] = useState(!!props.values);
  const [openEditPersonalInformationModal, setOpenEditPersonalInformationModal] = useState(false);
  const [openEditAnamnesisModal, setOpenEditAnamnesisModal] = useState(false);
  const handlePersonalInfoChange = (event) => {
    setIsPersonalInfoLocked(!event.target.checked);
  };

  const [isAnamnesisLocked, setIsAnamnesisLocked] = useState(!!props.values);
  const handleAnamnesisChange = (event) => {
    setIsAnamnesisLocked(!event.target.checked);
  };

  const [isFilesLocked, setIsFilesLocked] = useState(!!props.values);
  const handleFilesChange = (event) => {
    setIsFilesLocked(!event.target.checked);
  };

  const [files, setFiles] = useState([]);
  const [signatureFile, setSignatureFile] = useState([]);

  const currentValues = useRef(null);
  return (
    <Formik
      initialValues={props.values ? values : { ...INITAL_FORM_STATE }}
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values) => {
        setIsAnamnesisLocked(true);
        setIsFilesLocked(true);
        setIsPersonalInfoLocked(true);
        props.onSubmit(_values, files, signatureFile);
        setFiles([]);
        setSignatureFile([]);
      }}
      innerRef={currentValues}
    >
      {({ values, errors }) => {
        console.log('Formik values:', values);
        console.log('Current values:', currentValues.current?.values);
        return (
          <Form>
            <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
              {values && (
                <PDFDownloadLink
                  document={<UserMedicalHistoryPDF userData={values} />}
                  fileName="user_medical_history.pdf"
                >
                  {({ loading }) => {
                    // Directly set the isLoading state based on the loading prop
                    if (loading !== isPdfLoading) {
                      setIsPdfLoading(loading);
                    }

                    return (
                      <Button variant="contained" disabled={loading}>
                        {showPdfLoading ? 'Cargando documento...' : 'Descargar Historia Médica'}
                      </Button>
                    );
                  }}
                </PDFDownloadLink>
              )}
            </Grid>
            <Grid container columns={{ xs: 12 }}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom pt={3}>
                  Información personal
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right', marginTop: '21px' }}>
                {props.values && (
                  <FormControlLabel
                    control={
                      <Switch checked={!isPersonalInfoLocked} onChange={handlePersonalInfoChange} />
                    }
                    label="Editar información personal "
                    labelPlacement="start"
                  />
                )}
              </Grid>
            </Grid>
            <ToolTipWrapper
              condition={!isPersonalInfoLocked}
              title={
                'Para guardar sus cambios haga clic en el boton "Guardar cambios" al final de la pagina'
              }
            >
              <Grid container rowSpacing={2} columnSpacing={3} columns={{ xs: 6, sm: 6, md: 12 }}>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="firstName"
                      label="Nombres"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="lastName"
                      label="Apellidos"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={5}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <DateTimePicker
                      name="dateOfBirth"
                      label="Fecha de nacimiento"
                      inputProps={{
                        readOnly: isPersonalInfoLocked,
                        placeholder: 'dd/mm/aaaa'
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={1}>
                  {currentValues.current?.values?.dateOfBirth && (
                    <TextField
                      name="Edad"
                      label="(Edad)"
                      inputProps={{
                        readOnly: true,
                        value: getAge(currentValues.current.values.dateOfBirth)
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <Select
                      name="sex"
                      label="Género"
                      options={{ male: 'Masculino', female: 'Femenino', other: 'Otro' }}
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="placeOfBirth"
                      label="Lugar de nacimiento"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="cedula"
                      label="Cédula"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="email"
                      label="Correo electrónico"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="phoneNumber"
                      label="Número de teléfono"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="emergencyPhoneNumber"
                      label="Número de teléfono de emergencia"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="ocupation"
                      label="Ocupacion"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={12}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditPersonalInformationModal}
                  >
                    <TextField
                      name="address"
                      label="Dirección"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isPersonalInfoLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
              </Grid>
            </ToolTipWrapper>
            <br />
            <Grid container columns={{ xs: 12 }}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom pt={3}>
                  Anamnesis
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right', marginTop: '21px' }}>
                {props.values && (
                  <FormControlLabel
                    control={
                      <Switch checked={!isAnamnesisLocked} onChange={handleAnamnesisChange} />
                    }
                    label="Editar anamnesis"
                    labelPlacement="start"
                  />
                )}
              </Grid>
            </Grid>
            <ToolTipWrapper
              condition={!isPersonalInfoLocked}
              title={
                'Para guardar sus cambios haga clic en el boton "Guardar cambios" al final de la pagina'
              }
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={2}
                columns={{ xs: 6, sm: 6, md: 12 }}
                style={{ marginTop: '5px' }}
              >
                <Grid item xs={12}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditAnamnesisModal}
                  >
                    <TextField
                      name="medicalAntecedents"
                      label="Antecedentes personales"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isAnamnesisLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={12}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditAnamnesisModal}
                  >
                    <TextField
                      name="oralHistory"
                      label="Historia bucal"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isAnamnesisLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditAnamnesisModal}
                  >
                    <TextField
                      name="habits"
                      label="Hábitos"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isAnamnesisLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={6}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditAnamnesisModal}
                  >
                    <TextField
                      name="allergies"
                      label="Alergias"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isAnamnesisLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
                <Grid item xs={12}>
                  <HandleFocusAndBlur
                    disabled={isPersonalInfoLocked}
                    event={setOpenEditAnamnesisModal}
                  >
                    <TextField
                      name="familyAntecedents"
                      label="Antecedentes familiares"
                      multiline
                      rows={4}
                      variant="outlined"
                      inputProps={{
                        readOnly: isAnamnesisLocked
                      }}
                    />
                  </HandleFocusAndBlur>
                </Grid>
              </Grid>
            </ToolTipWrapper>
            <br />
            <Tooltip
              title={
                isFilesLocked
                  ? 'Haga clic en "Agregar archivos o firmas" para agregar Archivos'
                  : 'Para guardar sus cambios haga clic en el boton "Guardar cambios" al final de la pagina'
              }
              followCursor
            >
              <Grid container rowSpacing={2} columnSpacing={3}>
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom pt={3}>
                      Datos adjuntos
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right', marginTop: '21px' }}>
                    {props.values && (
                      <FormControlLabel
                        control={<Switch checked={!isFilesLocked} onChange={handleFilesChange} />}
                        label="Agregar archivos o firmas"
                        labelPlacement="start"
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} style={{ width: '100%' }}>
                    {props.values ? <PatientFiles /> : null}
                  </Grid>
                  <Grid item xs={12}>
                    {!isFilesLocked && <UploadFiles files={files} setFiles={setFiles} />}
                  </Grid>
                </>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                isFilesLocked
                  ? 'Haga clic en "Agregar archivos o firmas" para agregar Firmas'
                  : 'Para guardar sus cambios haga clic en el boton "Guardar cambios" al final de la pagina'
              }
              followCursor
            >
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12}>
                  {props.values ? <Signatures /> : null}
                </Grid>

                <Grid item xs={12}>
                  {!isFilesLocked && (
                    <InsertSignature
                      signatureFile={signatureFile}
                      setSignatureFile={setSignatureFile}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  {Object.keys(errors).length > 0 && (
                    <Alert severity="error">
                      {`Hay un error en el formulario. Por favor, revise la información ingresada. ${Object.values(
                        errors
                      )}`}
                    </Alert>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {(!isAnamnesisLocked || !isPersonalInfoLocked || !isFilesLocked) && (
                    <SubmitButtonWrapper disabled={Object.keys(errors).length > 0}>
                      Guardar cambios
                    </SubmitButtonWrapper>
                  )}
                </Grid>
              </Grid>
            </Tooltip>

            <Snackbar
              open={openEditPersonalInformationModal}
              autoHideDuration={6000}
              message='Haga clic en "Editar información personal " para editar'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
            <Snackbar
              open={openEditAnamnesisModal}
              autoHideDuration={6000}
              message='Haga clic en "Editar anamnesis" para editar'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
